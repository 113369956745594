<template>
  <v-dialog persistent v-model="open" width="600" attach="#main-container">
    <v-card class="br-6 pb-8 normal">
      <v-card-text class="py-4">
        <v-row>
          <v-col class="d-flex w-100 justify-space-between align-center">
            <div>
              <span class="h5 semiBold w-100">Rechazar descuento</span>
            </div>
            <v-btn icon @click="toggle" small class="ml-3">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <span class="p">Escriba el motivo del rechazo:</span>
            <v-textarea
              rows="4"
              outlined
              auto-grow
              row-height="20"
              v-model="motivo"
              background-color="white"
            ></v-textarea>
            <span class="p"
              >¿Estas seguro que desea rechazar el descuento?</span
            >
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <div class="d-flex justify-center">
              <v-btn
                rounded
                outlined
                color="primary"
                width="156"
                class="text-none"
                @click="toggle"
                >Cancelar</v-btn
              >
              <v-btn
                rounded
                @click="handleReject()"
                color="primary"
                width="240"
                class="text-none ml-8"
                >Rechazar descuento</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: ["toggle", "rejected", "item"],
  data() {
    return {
      open: true,
      motivo: "",
    };
  },

  methods: {
    handleReject() {
      this.rejected({ motivo: this.motivo, id: this.item.id });
    },
  },
};
</script>
<style scoped>
.bg-title {
  background-color: #e3eeff !important;
}
.border-table {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

:deep(.v-input__control .white) {
  background-color: white !important;
}
.normal {
  background-color: #f4f7fc !important;
}
</style>
