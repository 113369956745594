import { render, staticRenderFns } from "./Btn-Principal.vue?vue&type=template&id=0f16dea2&scoped=true"
import script from "./Btn-Principal.vue?vue&type=script&lang=js"
export * from "./Btn-Principal.vue?vue&type=script&lang=js"
import style0 from "../../assets/CSS/ServiceStyle.css?vue&type=style&index=0&id=0f16dea2&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f16dea2",
  null
  
)

export default component.exports