<template>
  <v-btn
    class="btn global-btn global-sbtn"
    depressed
    style="border-radius: 5px"
    large
    :width="width"
    :loading="loading"
    :height="height"
    :color="color"
    :disabled="disabled"
    >{{ label }}</v-btn
  >
</template>

<script>
export default {
  name: "v-btn-secondary",
  props: ["label", "color", "height", "width", "loading", "disabled"],
};
</script>
<style scoped src="../../assets/CSS/ServiceStyle.css"></style>
