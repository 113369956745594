import store from "@/store/index.js";

export const retomarCotizacion = (item) => {
  const params = {},
    query = {};
  let name = "dashboard";

  return new Promise((resolve, reject) => {
    const estado = item?.estado ?? "",
      nroPropuesta = item?.numero_propuesta;

    // Condiciones para tipo seleccionada y falta de informacion
    if (estado === "FAL" || estado === "SEL") {
      // Condicion de rechazo si no hay numero de propuesta
      if (!nroPropuesta) {
        reject(
          Error("No se ha encontrado el numero de propuesta en la cotización")
        );
      }
    }

    // Establecer la informacion del cliente
    store.dispatch("Users/setClient", {
      document_number: item.numero_documento,
      document_type: item.tipo_documento,
    });

    // Retomar propuesta creada en una cotizacion
    if (estado === "FAL") {
      // Contizacion de tipo Falta de informacion
      name = "Contract";
      params.id = nroPropuesta;
      query.paso = item?.step ?? 1;
    } else if (estado === "SEL") {
      // Cotizacion de tipo seleccionada
      name = "ShoppingCart";
      params.propuesta = nroPropuesta;
    } else if (estado === "A") {
      // Cotizacion de tipo Abierta
      name = "quotationOffers";
      params.id = item.numero_cotizacion;
    }

    resolve({
      name,
      params,
      query,
    });
  });
};

export default {
  retomarCotizacion,
};
