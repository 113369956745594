<template>
  <div class="text-center">
    <v-dialog
      v-model="open"
      max-width="880px"
      height="327px"
      persistent
      attach="#main-container"
    >
      <v-card class="global-modalCard3">
        <v-layout fill-height class="pa-0">
          <v-flex class="text-left pl-6 pt-10 mt-2 ml-10">
            <h1 class="global-titleStyle10">Autorizar descuento</h1>
            <v-spacer></v-spacer>

            <h4 class="mt-12 global-subtitleStyle6 black--text">
              ¿Desea autorizar el uso del descuento <br />
              al usuario con el
              <span class="black--text"
                >RUT
                {{ userdata.rut | rutFormat }}
              </span>

              ?
            </h4>
            <div class="global-textfield mt-6">
              <v-form ref="form">
                <TextField :fields="text_descuento" class="mt-4" />
              </v-form>
            </div>
          </v-flex>

          <v-flex class="d-flex align-center justify-end">
            <div
              id="ComputerSvg"
              style="align-items: center; justify-content: center"
            >
              <v-flex class="mb-5 text-end justify-end">
                <v-btn @click="close" icon class="mt-5 mr-7">
                  <v-icon class="icon text-end">mdi-close</v-icon>
                </v-btn>
              </v-flex>
              <v-img
                class="global-image6"
                max-width="160px"
                src="@/assets/modalImages/descuento.png"
              />
              <div class="global-buttons mt-3 mb-7 d-flex">
                <btnsecundario
                  color="#ddecf7"
                  label="Rechazar"
                  v-on:click.native="rechazar(userdata)"
                  class="mr-6 pr-6 pl-6"
                  :loading="loadingrechazar"
                  width="153"
                  height="45"
                />
                <btnprincipal
                  color="#0077c8"
                  label="Autorizar"
                  v-on:click.native="authorize(userdata)"
                  :loading="loadingstatus"
                  class="mr-6 pr-6 pl-6"
                  width="153"
                  height="45"
                />
              </div>
            </div>

            <v-img
              max-width="450px"
              width="430"
              id="BackgroundSvgs"
              src="@/assets/modalImages/Vector.png"
            />
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import btnprincipal from "@/components/Button/Btn-Principal.vue";
import btnsecundario from "@/components/Button/Btn-Secundario.vue";
export default {
  data: () => ({
    dialog: true,
    text_descuento: {
      label: "Porcentaje",
      text: "",
      rules: [(value) => !!value || "Porcentaje es requerido."],
    },
  }),
  props: ["open", "userdata", "loadingstatus", "loadingrechazar"],
  components: {
    btnprincipal,
    btnsecundario,
  },
  methods: {
    close() {
      this.$refs.form.reset();
      this.$emit("close");
      this.text_descuento.text = null;
    },
    authorize(userdata) {
      if (this.$refs.form.validate()) {
        userdata.descuento_porcentaje = this.text_descuento.text.replace(
          "%",
          ""
        );

        this.$emit("authorize", userdata);
      }
    },
    rechazar(userdata) {
      this.$emit("rechazar", userdata);
    },
  },
  watch: {
    open() {
      if (this.userdata && this.open === true) {
        `${(this.text_descuento.text =
          this.userdata.descuento_porcentaje + "%")}`;
      }
    },
  },
};
</script>

<style scoped>
h4 {
  z-index: 10;
}
#BackgroundSvgs {
  position: absolute;
  top: 0%;
  max-width: 450px;
}
#ComputerSvg {
  z-index: 10;
}
:deep() .v-dialog {
  border-radius: 4px;
  margin: 24px;
  overflow: hidden;
  pointer-events: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 100%;
}
</style>
