<template>
  <v-btn
    class="global-btn global-pbtn"
    depressed
    style="border-radius: 5px"
    large
    :width="width"
    :to="to"
    :loading="loading"
    :height="height"
    :color="btnColor"
    :disabled="disabled"
    :block="block"
    >{{ label }}</v-btn
  >
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "v-btn-principal",
  props: [
    "label",
    "color",
    "height",
    "width",
    "to",
    "disabled",
    "loading",
    "block",
  ],
  computed: {
    ...mapGetters("Users", ["getBotonColor"]),
    btnColor() {
      if (this.getBotonColor != null) {
        return this.getBotonColor;
      } else {
        return "#0077c8";
      }
    },
  },
};
</script>
<style scoped src="../../assets/CSS/ServiceStyle.css"></style>
