<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
    :disabled="disabled"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        outlined
        v-model="computedDateFormatted"
        :label="label"
        readonly
        v-bind="attrs"
        v-on="on"
        dense
        :rules="inputRules"
        :placeholder="placeholder ? placeholder : ''"
        :background-color="backgroundColor"
      >
        <template slot="append" v-if="icon === 'right'">
          <v-icon>mdi-calendar-month</v-icon>
        </template>
        <template slot="prepend-inner" v-else>
          <v-icon>mdi-calendar-month</v-icon>
        </template>
      </v-text-field>
    </template>
    <v-date-picker
      locale="es-MX"
      v-model="field"
      :type="type"
      no-title
      scrollable
      @input="change"
      :min="min === true ? minim : ''"
      :max="max === true ? maxim : ''"
    >
    </v-date-picker>
  </v-menu>
</template>
<script>
export default {
  props: {
    icon: {
      type: String,
      default: "right",
    },
    required: {
      type: Boolean,
      default: false,
    },
    date: { type: String },
    color: { type: String },
    label: { type: String },
    min: { type: Date },
    max: { type: Date },
    disabled: { type: Boolean },
    type: { type: String },
    placeholder: { type: String },
    backgroundColor: { type: String },
  },
  computed: {
    inputVal: {
      get() {
        return this.date;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    computedDateFormatted() {
      return this.formatDate();
    },
    inputRules() {
      if (this.required) {
        return [this.rules.required];
      } else {
        return [];
      }
    },
  },
  data() {
    return {
      minim: "",
      maxim: "",
      menu: false,
      modal: false,
      field: this.date,
      rules: {
        required: (value) => !!value || "Campo requerido.",
      },
    };
  },

  methods: {
    change() {
      this.menu = false;
      this.$emit("input", this.field);
    },
    formatDateTwo(date) {
      var [day, month, year] = date.toLocaleDateString().split("-");
      if (day) return `${year}-${month}-01`.toString();
    },
    formatDateThree(date) {
      var [day, month, year] = date.toLocaleDateString().split("-");

      return `${year}-${month}-${day}`;
    },
    formatDate() {
      if (!this.field) return null;

      const [year, month, day] = this.field.split("-");
      return `${month}/${day !== undefined ? day + "/" : ""}${year}`;
    },
  },
  created() {
    this.$emit("input", this.field);
    this.minim = this.formatDateTwo(
      new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
    );
    this.maxim = this.formatDateThree(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getUTCDate()
      )
    );
  },
};
</script>
