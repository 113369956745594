import { retomarCotizacion } from "@/helpers/cotizacion.js";

export const resumeQuote = {
  methods: {
    // === Metodo de retoma de una cotizacion ===
    Retomar(item) {
      item.loading = true;
      retomarCotizacion(item)
        .then((response) => {
          this.$router.push(response);
        })
        .catch((e) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: e.toString(),
            top: true,
            right: true,
            color: "error",
          });
        })
        .finally(() => {
          item.loading = false;
        });
    },
  },
};

export default resumeQuote;
