import { render, staticRenderFns } from "./Descuentos.vue?vue&type=template&id=0f43bbac&scoped=true"
import script from "./Descuentos.vue?vue&type=script&lang=js"
export * from "./Descuentos.vue?vue&type=script&lang=js"
import style0 from "../../assets/CSS/SimpleTable.css?vue&type=style&index=0&id=0f43bbac&prod&scoped=true&lang=css&external"
import style1 from "./Descuentos.vue?vue&type=style&index=1&id=0f43bbac&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f43bbac",
  null
  
)

export default component.exports