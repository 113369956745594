<template name="component-name">
  <label>{{ text }}</label>
</template>
<script>
export default {
  name: "inputLabel",

  props: {
    text: String,
  },
};
</script>
<style scoped>
label {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #223a65;
  margin: 7px 0;
}
</style>
