import { render, staticRenderFns } from "./Modal-discount-reject-reason.vue?vue&type=template&id=cc8212fe&scoped=true"
import script from "./Modal-discount-reject-reason.vue?vue&type=script&lang=js"
export * from "./Modal-discount-reject-reason.vue?vue&type=script&lang=js"
import style0 from "./Modal-discount-reject-reason.vue?vue&type=style&index=0&id=cc8212fe&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc8212fe",
  null
  
)

export default component.exports